<template>

  <div class="param">


    <div v-if="label" class="label">
      {{ label }}
    </div>

    <div class="setup_ico setup_ico_week"></div>


    <div class="bx">
      <div class="vl">
        <div class="inp">          
          <WeekEditParamSelectWeeks
            :val="props.num"
            :options="options_weeks"
            @update:val="emits('update:num', $event)"
            />
        </div>
      </div>
      <div class="units">
        <div           
          :class="'unit select unit_1'">
          {{ $t('set_week') }}
        </div>
      </div>
    </div>

    <div class="hnt">
      <WeekEditParamSelectFaza
        :val="props.faza"
        :options="options_faza"
        @update:val="emits('update:faza', $event)"
        />
    </div>
     
  </div>
  
</template>


<script setup>

const emits = defineEmits(['update:num, update:faza'])
const props = defineProps({
  faza: {      
    required: true,
  },  
  num: {      
    required: true,
  },   
  label: {
    type: String, 
  }, 
  param: {
    type: String, 
  },
  weeks: {
    type: Array, 
    default: [],
  },
})

const options_faza = ref([
  {id: 0, name: 'Vegetation'},
  {id: 1, name: 'Flowering'},
]);
const options = ref([
  {id: 1, name: '1 week'},
  {id: 2, name: '2 weeks'},
  {id: 3, name: '3 weeks'},
  {id: 4, name: '4 weeks'},
  {id: 5, name: '5 weeks'},
  {id: 6, name: '6 weeks'},
  {id: 7, name: '7 weeks'},
  {id: 8, name: '8 weeks'},
  {id: 9, name: '9 weeks'},
  {id: 10, name: '10 weeks'},
  {id: 11, name: '11 weeks'},
  {id: 12, name: '12 weeks'},
  {id: 13, name: '13 weeks'},
  {id: 14, name: '14 weeks'},
  {id: 15, name: '15 weeks'},
  {id: 16, name: '16 weeks'},
  {id: 17, name: '17 weeks'},
  {id: 18, name: '18 weeks'},
  {id: 19, name: '19 weeks'},
  {id: 20, name: '20 weeks'},
  {id: 21, name: '21 weeks'},
  {id: 22, name: '22 weeks'},
  {id: 23, name: '23 weeks'},
  {id: 24, name: '24 weeks'},
  {id: 25, name: '25 weeks'},
  {id: 26, name: '26 weeks'},
  {id: 27, name: '27 weeks'},
  {id: 28, name: '28 weeks'},
  {id: 29, name: '29 weeks'},
  {id: 30, name: '30 weeks'},
])

const options_weeks = computed(() => {
  var res = [];
  options.value.forEach(option => {
    
    option.enabled = true;
    
    if(props.weeks)
      props.weeks.forEach(w => {
        if(w.days == option.id)
          option.enabled = false;
      });
    if(option.enabled)
      res.push(option);
  });

  return res;
  
})

  
</script>
 

<style scoped>
  @import '@/assets/css/param.css';
</style>



<style scoped>
   
 



</style>