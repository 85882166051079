<template>

  <div class="param smell">

    <div :class="'setup_ico ' + ico"></div>

    <div class="bx">
      <div class="vl">
        

        <div :class="'smell_size ' + (num ? 'active' : '')">
          <div :class="'smell_item size_1 ' + (num == 1 ? 'active' : '')" @click="change(1)">
            <i class="setup_icon icon-smell-no"></i>
            <div class="name">{{ $t('set_nosmell') }}</div>
          </div>
          <div :class="'smell_item size_2 ' + (num == 2 ? 'active' : '')" @click="change(2)">
            <i class="setup_icon icon-smell"></i>
            <div class="name">{{ $t('set_weak') }}</div>
          </div>
          <div :class="'smell_item size_3 ' + (num == 3 ? 'active' : '')" @click="change(3)">
            <i class="setup_icon icon-smell"></i>
            <div class="name">{{ $t('set_normal') }}</div>
          </div>
          <div :class="'smell_item size_4 ' + (num == 4 ? 'active' : '')" @click="change(4)">
            <i class="setup_icon icon-smell"></i>
            <div class="name">{{ $t('set_strong') }}</div>
          </div>
        </div>



      </div> 
    </div>

    <div class="hnt">
      {{ param }}
    </div>
     
  </div>
  
</template>


<script>

export default {
  props:{
    num: {      
      required: true,
    },
    ico: {
      type: String,
      required: true,
    }, 
    param: {
      type: String, 
    }, 
  },  
  data() {
    return { 
    };
  }, 
  mounted () {
   
  },
  methods: {
    change(vl){
      // if(this.num == vl)
      //   this.num = 0
      // else
      //   this.num = vl
      this.$emit('update:num', vl);

    }
  }
}
</script>
 


<style scoped>
  @import '@/assets/css/param.css';
</style>

<style scoped>
   
 



</style>