<template>

  <div class="radb">    
    <template
      v-for="(item, item_key) in $constants.typeMethodShort"
      >
      <div 
        :class="'ritem' + (isSelected(item_key) ? ' checked ' : '')"
        @click="toggle(item_key)"
        >
        {{ item ? $t(item) : '' }}
      </div>
    </template>
 
    <template
      v-for="(item,item_key) in props.selected"
      >
      <div 
        v-if="item.method_id < 0 && !item.is_remove"
        class="ritem checked"
        @click="removeCustom(item_key)"
        >
        {{ item.custom }}
      </div>
    </template>
    
    
    <div class="custom_form">
      <input type="text" v-model="custom_method" placeholder="Add method" @keypress.enter="addCustom">
      <i class="icon-plus" @click="addCustom"></i>
    </div>
  
  </div>
  
</template>


<script setup>

var custom_method = ref('');

const emits = defineEmits(['add', 'remove'])

const props = defineProps({
  list: {
    type: Array,
    default: () => []
  },
  selected: {
    type: Array,
    default: () => []
  },
})

const addCustom = () => {
  if(custom_method.value){        
    emits('add', -1, custom_method.value)
    custom_method.value = '';
  }
}

const removeCustom = function(item_key){      
  emits('remove', -1, item_key)
}

const isSelected = function(method_id){
  var selected = false;
  for(var s in props.selected){
    if(props.selected[s].method_id == method_id && !props.selected[s].is_remove){                     
      selected = true;
    }
  }
  return selected;
}


const toggle = function(method_id){
  if(isSelected(method_id)){
    emits('remove', method_id)
  }else{
    emits('add', method_id)
  }
}



</script>
 


<style scoped>
   
.radb{
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
}
.custom_form{
  width: 100%;
  display: flex;
  justify-content: center;
}
.custom_form input{
  max-width: 300px;  
}
.custom_form i{
    background-color: var(--un-primary-back-color);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    border-radius: 5px;
    margin-left: -40px;
    cursor: pointer;
}

.ritem {
  padding: 10px 13px;
  background-color: var(--un-background-color-gray);
  border-radius: 53px;
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  color: var(--un-text-color);
  
}

.ritem:hover {
  background-color: var(--un-background-color-gray-light);
}

.ritem.checked {
  background-color: var(--un-primary-back-color);
  color: white;
}

</style>