<template>

  <div class="param">


    <div v-if="label" class="label">
      {{ label }}
    </div>


    <div class="setup_ico setup_ico_week"></div>

    <div class="bx">
      <div class="vl">
        <div class="inp">
           
          <WeekEditParamSelectWeeks
            :val="edited_num"
            :options="options_weeks" 
            @update:val="changeNum"         
            />

        </div>
      </div>
      <div class="units">
        <div  
          v-for="(opt,opt_key) in units"
          :key="opt_key"
          :class="'unit unit_' + units.length">
          {{ opt.name }}
        </div>
      </div>
    </div>

    <div class="hnt">
      {{ $t('create_harvest_view_outcome_week_harvest') }}
    </div>
     
  </div>
  
</template>


<script>
 
export default {
  props:{ 
    num: {      
      required: true,
    },  
    units: {
      type: Array,
      required: true,
    },
    label: {
      type: String, 
    }, 
    param: {
      
    }, 
  },
  components: {     
   
  },  
  data() {
    return { 
      edited_num: this.num,
    };
  }, 

  mounted () {
   
  },
  computed: {
    options_weeks(){
      return [
        {id: 1, name: '1 week'},
        {id: 2, name: '2 weeks'},
        {id: 3, name: '3 weeks'},
        {id: 4, name: '4 weeks'},
        {id: 5, name: '5 weeks'},
        {id: 6, name: '6 weeks'},
        {id: 7, name: '7 weeks'},
        {id: 8, name: '8 weeks'},
        {id: 9, name: '9 weeks'},
        {id: 10, name: '10 weeks'},
        {id: 11, name: '11 weeks'},
        {id: 12, name: '12 weeks'},
        {id: 13, name: '13 weeks'},
        {id: 14, name: '14 weeks'},
        {id: 15, name: '15 weeks'},
        {id: 16, name: '16 weeks'},
        {id: 17, name: '17 weeks'},
        {id: 18, name: '18 weeks'},
        {id: 19, name: '19 weeks'},
        {id: 20, name: '20 weeks'},
        {id: 21, name: '21 weeks'},
        {id: 22, name: '22 weeks'},
        {id: 23, name: '23 weeks'},
        {id: 24, name: '24 weeks'},
        {id: 25, name: '25 weeks'},
        {id: 26, name: '26 weeks'},
        {id: 27, name: '27 weeks'},
        {id: 28, name: '28 weeks'},
        {id: 29, name: '29 weeks'},
        {id: 30, name: '30 weeks'},
      ];
    },
    options_faza(){
      return [
        {id: 0, name: 'Vegetation'},
        {id: 1, name: 'Flowering'},
      ];
    },

  },
  methods: {
  
    changeNum(vl){ 
      this.edited_num = vl;      
      this.$emit('update:num', this.edited_num);
    },
  }
}
</script>
 


<style scoped>
  @import '@/assets/css/param.css';
</style>

<style scoped>
   
 



</style>