<template>

  <div class="param">
 
    <div :class="'setup_ico ' + ico"></div>

    <div class="bx">
      <div class="vl">
        <input type="text" v-model="edited_num" @keyup="change" class="inp_fm">        
      </div>
      <div class="units">
        <div  
          v-for="(opt,opt_key) in units"
          :key="opt_key"
          :class="'unit unit_' + units.length">
          {{ opt.name }}
        </div>
      </div>
      <input type="range" class="range" max="24" min="0" v-model="edited_num" @change="change">
    </div>

    <div class="hnt">
      {{ param }}
    </div>
     
  </div>
  
</template>


<script setup>

const emits = defineEmits(['update:num']);

const props = defineProps({
  num: {      
    required: true,
  },
  ico: {
    type: String,
    required: true,
  },
  measure: {
    type: String, 
  },
  unit: {
    type: String, 
  },
  units: {
    type: Array, 
  },
  param: {
    type: String, 
  },
  numMin: {
    type: Number, 
  },
  numMax: {
    type: Number, 
  },
});

const edited_num = ref(props.num);
const edited_num_prev = ref('');

const validateInput = () => {
  const regex = /^\d*$/;
  if (regex.test(edited_num.value)) {
    edited_num_prev.value = edited_num.value;
  } else {
    edited_num.value = edited_num_prev.value; 
  }

  if(props.numMin && Number(edited_num.value) < props.numMin) {
    edited_num.value = props.numMin;
  }else if(props.numMax && Number(edited_num.value) > props.numMax) {
    edited_num.value = props.numMax;
  }
}

const change = () => {
  validateInput();
  emits('update:num', edited_num);
};


watch(() => props.num, (val) => {
  edited_num.value = val
});

// export default {
//   props:[    
//     'num',
//     'ico',
//     'units',
//     'param',
//   ],
//   data() {
//     return { 
//       edited_num: this.num
//     };
//   }, 

//   mounted () {
   
//   },
//   methods: {  
//     change(){            
//       this.$emit('update:num', this.edited_num);
//     }
//   }
// }
</script>
 


<style scoped>
  @import '@/assets/css/param.css';
</style>

<style scoped>
   
 



</style>