<template>

  <div class="prgl"> 
 
    <div 
      class="prog"
      :style="{
        width: props.percent + '%'
      }"
      >
      <div v-if="is_show_text" class="txt">
        {{ props.percent }}%
      </div>
    </div>
    
        
  </div> 

</template>

<script setup>
 
import { watch } from 'vue';

const props = defineProps({
  percent: {
    type: Number,
    default: 0
  },
  is_show_text: {
    type: Boolean,
    default: true
  }
})

watch(() => props.percent, (new_percent, old_percent) => {
  props.percent = new_percent;
}, { deep: true })


</script>

<style scoped>
 
.prgl{
  width: 100%;
  height: 28px;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.prog{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--un-primary-back-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  padding: 0 5px;
  box-sizing: border-box;
  transition: width 0.5s;
}

.txt{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
  padding: 0 5px;
  box-sizing: border-box;
  transition: width 0.5s;
}

</style>
