<template>
  <div class="">    

    <div class="divider">{{ $t('create_week_view_navigation_title') }}</div>

    
    <DiaryEditProgress
      :steps="progress_steps"
      :states="progress_states"
      />

    <div class="divider" ref="elWeekStrain">{{ $t('create_harvest_view_strain_title') }}</div>
 
    <!-- {{ edited_week.item_harvest.item_review_seed.item_brand }} -->
    <WeekEditSeedReviewHarvest 
      :data="edited_week" 
      />

    <div class="divider" ref="elWeekResult">{{ $t('create_harvest_view_outcome_title') }}</div>

    <div class="parameters">
 

    <WeekEditParamWeekHarvest       
      :num="edited_week.days"
      :param="false"
      :label="$t('create_harvest_view_outcome_week')"
      :units="[{id: 'week', name: 'Week'}]"
      :is-presaved="edited_week.is_presaved"
      @update:num="edited_week.days = $event"
      />

    <WeekEditParamNumber       
      :num="edited_week.props.harvest_wet_weight"
      :ico="'setup_ico_n_wet_weight'"
      :label="$t('create_harvest_view_outcome_wet_title')"
      :param="$t('create_harvest_view_outcome_wet')"
      :measure="useAuth().getWeight()"
      :units="[{id: 'english', name: 'ounce'}, {id: 'metric', name: 'gram'}]"
      :is-presaved="edited_week.is_presaved"
      @update:num="edited_week.props.harvest_wet_weight = $event"
      @change_measure="useAuth().setWeight($event)"
      />

    <WeekEditParamNumber       
      :num="edited_week.props.harvest_plant"
      :ico="'setup_ico_n_plant'"
      :label="$t('create_harvest_view_outcome_plants_title')"
      :param="$t('create_harvest_view_outcome_plants')"
      :units="[{id: 'plant', name: 'plant'}]"
      :is-presaved="edited_week.is_presaved"
      @update:num="edited_week.props.harvest_plant = $event"
      />

    <WeekEditParamNumber       
      :num="edited_week.props.harvest_watt"
      :ico="'setup_ico_n_watt'"
      :label="$t('create_harvest_view_outcome_light_title')"
      :param="$t('create_harvest_view_outcome_light')"
      :units="[{id: 'watt', name: 'watt'}]"
      :is-presaved="edited_week.is_presaved"
      @update:num="edited_week.props.harvest_watt = $event"
      />

    <WeekEditParamNumber       
      :num="edited_week.props.harvest_space"
      :ico="'setup_ico_n_space'"
      :label="$t('create_harvest_view_outcome_room_title')"
      :param="$t('create_harvest_view_outcome_room')"
      :measure="useAuth().getLength()"
      :units="[{id: 'metric', name: 'm2'}, {id: 'english', name: 'ft2'}]"
      :is-presaved="edited_week.is_presaved"
      @change_measure="useAuth().setLength($event)"
      @update:num="edited_week.props.harvest_space = $event"
      />
 

    </div>


    <div class="divider" ref="elWeekPhotos">{{ $t('photo_video_manager_external_title') }}</div>    

    <WeekEditWarningPhotos/>

    <div class="udr_bx">
      <UiUploader
        :enable-drag-drop="!edited_week.items_photo?.filter((opt) => opt.is_smoke != 1).length"
        @changed="changePhotos" 
        @uploaded="uploadedPhotos" 
        @progress="uploadingPhotos" 
        @errored="erroredPhotos"
      />
    </div>
    



 
    <draggable 
      v-model="edited_week.items_photo" 
      item-key="id" 
      v-if="edited_week.items_photo" 
      @start="drag=true"  
      @end="drag=false" 
      class="photos"
      delay-on-touch-only="250"
      delay="250"
      animation="200"
        ghost-class="photos_ghost"
      >

        <template #item="{element}">
          <WeekEditPhoto
            :item="element"
            v-show="element?.is_remove != 1"
            @remove="removePhoto(element.id)"
            @update:item="element = $event"
            @rotate="saveRotate"
            />
        </template>


    </draggable>
     

    <!-- <VueDraggable 
        v-if="edited_week.items_photo" 
        @start="drag=true"  
        @end="drag=false" 
        delay-on-touch-only="250"
        delay="250"
        animation="200"
        class="photos"
        ghost-class="photos_ghost"
        v-model="edited_week.items_photo" 
        item-key="id" 
        > 

          <WeekEditPhoto
            v-for="(element,element_key) in edited_week.items_photo?.filter((opt) => opt.is_smoke != 1)"
            :item="element"
            v-show="element?.is_remove != 1"
            @remove="removePhoto(element.id)"
            @update:item="element = $event"
            @rotate="saveRotate"
            />


      </VueDraggable> -->
  

    <template v-if="edited_week.item_harvest?.items_review?.length">
      <!-- <div class="divider" ref="elWeekNutrients">Reviews</div> -->
      <!-- <div class="product-review-subtitle">{{ $t('create_harvest_view_nutrients_reviews_subtitle') }}</div>     -->
      <WeekEditProductReviewHarvest
        :reviews="edited_week.item_harvest?.items_review"
        />
    </template>
  
<!-- 
    <template v-if="edited_week.item_harvest?.items_nutrient?.length">
      <div class="divider" ref="elWeekNutrients">{{ $t('create_harvest_view_nutrients_reviews_title') }}</div>    
      <div class="product-review-subtitle">{{ $t('create_harvest_view_nutrients_reviews_subtitle') }}</div>    
      <WeekEditNutrientReviewHarvest
        :data="edited_week.item_harvest?.items_nutrient"
        />
    </template>

    <template v-if="edited_week.item_harvest?.items_lamp?.length">
      <div class="divider" ref="elWeekLamps">{{ $t('create_harvest_view_lamps_reviews_title') }}</div>      
      <div class="product-review-subtitle">{{ $t('create_harvest_view_nutrients_reviews_subtitle') }}</div>     
      <WeekEditLampReviewHarvest
        :data="edited_week.item_harvest?.items_lamp"
        />
    </template>

    <template v-if="edited_week.item_harvest?.items_tent?.length">
      <div class="divider" ref="elWeekTents">{{ $t('create_harvest_view_tents_reviews_title') }}</div>      
      <div class="product-review-subtitle">{{ $t('create_harvest_view_nutrients_reviews_subtitle') }}</div>     
      <WeekEditTentReviewHarvest
        :data="edited_week.item_harvest?.items_tent"
        />
    </template> -->


    <div class="divider" ref="elWeekComment">{{ $t('create_week_view_owner_comment_title') }}</div>    
 
    <UiTextArea 
      :text="edited_week.text"
      @change:text="edited_week.text = $event"
      />
 

    <UiButtons>
      <UiButton type="primary" :is-loading="props.isLoadingSave" :name="$t('universal_button_save')" @click="save"/>      
      <UiButton :name="$t('universal_button_cancel')" @click="cancel"/>              
    </UiButtons>


  </div>
</template>



<script setup>

import draggable from 'vuedraggable'

const route = useRoute()
const router = useRouter()
const { $progressBarContent, $api, $popup } = useNuxtApp()
const { t } = useI18n()
const emits = defineEmits(['saveweek', 'canceleditweek'])
const elWeekStrain = ref(null)
const elWeekPhotos = ref(null)
const elWeekResult = ref(null)
const elWeekComment = ref(null)
const elWeekNutrients = ref(null)
const elWeekLamps = ref(null)
const elWeekTents = ref(null)

const props = defineProps({
  week: {
    type: Object,
    required: true,
  },
  diary: {
    type: Object,
    required: true,
  },
  isLoadingSave: {
    type: Boolean,
    required: false,
    default: false,
  },
})

var is_loaded = ref(false)
var link = ref(route.params['week'])
var edited_week = ref(props.week)

const progress_steps = computed(() => {
  var ret = [];
  ret.push({
    id: 'strain',
    name: t('create_harvest_view_navigation_strain'),
    hintGood: t('create_harvest_view_navigation_strain_description1'),
    hintNormal: t('create_harvest_view_navigation_strain_description2'),
    hintBad: t('create_harvest_view_navigation_strain_description3'),
    el: elWeekStrain,
  })
  ret.push({
    id: 'result',
    name: t('create_harvest_view_navigation_outcome'),
    hintGood: t('create_harvest_view_navigation_outcome_description1'),
    hintNormal: t('create_harvest_view_navigation_outcome_description2'),
    hintBad: t('create_harvest_view_navigation_outcome_description3'),
    el: elWeekResult,
  });
  ret.push({
    id: 'photos',
    name: t('create_week_view_navigation_photos_videos'),
    hintGood: t('create_week_view_navigation_now_talking'),
    hintNormal: t('create_week_view_navigation_more_photos_videos'),
    hintBad: t('create_week_view_navigation_some_photos_videos'),
    el: elWeekPhotos,
  }); 
  // console.log('edited_week.value.item_harvest.items_nutrient?.length')
  // console.log(edited_week.value.item_harvest.items_nutrient?.length)
  if(edited_week.value.item_harvest.items_nutrient?.length)
    ret.push({
      id: 'nutrients',
      name: t('create_week_view_navigation_nutrients'),
      hintGood: t('create_week_view_navigation_you_rock'),
      hintNormal: t('create_week_view_navigation_feeding_them'),
      hintBad: t('create_week_view_navigation_feeding_them'),
      el: elWeekNutrients,
    });
  ret.push({
    id: 'comment',
    name: t('create_week_view_navigation_comment'),
    hintGood: t('create_week_view_navigation_cool_info'),
    hintNormal: t('create_week_view_navigation_wanna_tell'),
    hintBad: t('create_week_view_navigation_share_ideas'),
    el: elWeekComment,
  });

  return ret

});

const progress_states = computed(() => {
  // console.log('$progressBarContent');
  // console.log($progressBarContent.calcWeekVeg);

  var ret = $progressBarContent.calcWeekHar(edited_week.value);

  return ret;
})



const changePhotos = function(uploading_id, thumbnail){
  edited_week.value.items_photo = edited_week.value.items_photo || [];
  edited_week.value.items_photo.push({
    is_loading: true, 
    uploading_id: uploading_id, 
    thumbnail: thumbnail,
    is_smoke: 0,
  });
}

const findReplaceUploadingPhoto = function(uploading_id, item){
  edited_week.value.items_photo.forEach((opt, opt_key) => {
    if(opt?.uploading_id == uploading_id){
      edited_week.value.items_photo[opt_key] = item; 
    }
  });
}

const findReplaceUploadingPhotoProgress = function(uploading_id, progress){
  edited_week.value.items_photo.forEach((opt, opt_key) => {
    if(opt?.uploading_id == uploading_id){
      edited_week.value.items_photo[opt_key]['progress'] = progress; 
    }
  });
}
const uploadingPhotos = function(progress, uploading_id){      
  edited_week.value.items_photo = edited_week.value.items_photo || [];
  findReplaceUploadingPhotoProgress(uploading_id, progress);
}

const uploadedPhotos = function(item, uploading_id){      
  edited_week.value.items_photo = edited_week.value.items_photo || [];
  item.is_smoke = 0;
  findReplaceUploadingPhoto(uploading_id, item);
}

const erroredPhotos = function(uploading_id){      
  $popup.error('Error uploading file');
  removePhoto(null, uploading_id);
}

const removePhoto = function(id, uploading_id){            
  edited_week.value.items_photo.forEach((opt, opt_key) => {
    if(opt.id && opt.id == id){
      edited_week.value.items_photo[opt_key].is_remove = 1; 
    }else if(opt.uploading_id && opt.uploading_id == uploading_id) {
      edited_week.value.items_photo[opt_key].is_remove = 1; 
    }
  });
}

const cancel = function(){  
  emits('canceleditweek', edited_week.value.id)
}

const save = function(){            
  // edited_week.value.item_harvest.items_nutrient = edited_week.value?.item_harvest?.items_nutrient ?? [];
  emits('saveweek', edited_week.value)
}

const setPhotoLoading = function(id, state){
  edited_week.value.items_photo.forEach((opt, opt_key) => {
    if(opt.id == id){
      console.log('is_loading photo' + id + ' state: ' + state);
      edited_week.value.items_photo[opt_key].is_loading = state;
    }
  });
}

const setPhoto = function(id, item){
  console.log('setPhoto photo' + id + ' item: ' + item.size_m);
  edited_week.value.items_photo.forEach((opt, opt_key) => {
    if(opt.id == id){
      edited_week.value.items_photo[opt_key].size_xl = item.size_xl;
      edited_week.value.items_photo[opt_key].size_l = item.size_l;
      edited_week.value.items_photo[opt_key].size_m = item.size_m;
      edited_week.value.items_photo[opt_key].size_s = item.size_s;
      edited_week.value.items_photo[opt_key].size_xs = item.size_xs;
    }
  });
}

const saveRotate = function(item, rotate_value){

  setPhotoLoading(item.id, true);

  $api.postRotatePhoto(item.id, rotate_value)
  .then((res) => {
    setPhotoLoading(item.id, false);
    setPhoto(item.id, res.data.value.data);
  })
  .catch((err) => {
    setPhotoLoading(item.id, false);
    $popup.error('Error rotate photo');
  })
}



const loadData = async (id) => {
  let data = await $api.getDiaryHarvestNutrientsReview(id);  
  is_loaded = true;
  return data;
}


// const { pending: is_loading,  data: nutrients_data } = await useLazyAsyncData('nutrients_data', async () => await loadData(props.diary.id))

// watch(() => route.params['week'], async (newVal, oldVal) => {
//   const { pending: is_loading,  data: nutrients_data } = await useLazyAsyncData('nutrients_data', async () => await loadData(props.diary.id))
// })


watch(() => props.week, (new_props_week, old_props_week) => {
  edited_week.value = new_props_week;
}, {deep: true});



 
</script>

<style scoped>

  .parameters{
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
 

  .photos{
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap:15px;
    -webkit-user-select: none; 
    -ms-user-select: none; 
    user-select: none;
  }
  .photos_ghost{
  opacity: 0.5;
  background: #bee85d;
  }
  .udr_bx{
    text-align: center;
    margin: 1rem;
  }


  .product-review-subtitle{
    margin-bottom: 1rem;
    text-align: center;
    font-weight: bold;
  }

</style>
