<template>

  <div class="radb">    
 
    <template
      v-for="(item, item_key) in list"
      >
      <div 
        :class="'ritem' + (isSelected(item_key) ? ' checked ' : '')"
        @click="choose(item_key)"
        >
        {{ item ? $t(item) : '' }}
      </div>
    </template>
  
     
  </div>
  
</template>


<script setup>

const emits = defineEmits(['update:val'])

const props = defineProps({
  list: {
    type: Object
  },
  val: {
    type: Object
  },
})

const sel = ref(props.val);



const isSelected = (item_key) => {
  if(!sel.value)
    return false;
  return sel.value.id == item_key ? true : false;
}

const choose = (item_key) => {
  sel.value = {
    id: item_key
  }; 
  emits('update:val', sel.value);
}


// export default { 
//   props:{
//     list: {
//       type: Object
//     },
//     val: {
//       type: Object
//     },
//   },
//   data() {
//     return {      
//       sel: this.val
//     };
//   },  
//   mounted () {
    
//   },
//   methods: { 
//     isSelected(item_key){
//       return this.sel.id == item_key ? true : false;
//     }, 
//     choose(item_key){

//       this.sel = {
//         id: item_key
//       }; 
      
//       this.$emit('update:val', this.sel);
//     }
//   }
// }
</script>
 


<style scoped>
   
.radb{
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
} 
.ritem {
  padding: 10px 13px;
  background-color: #ffffff;
  border-radius: 53px;
  display: inline-block;
  margin-bottom: 5px;
  /* border: 2px #d0d0d0 solid; */
  cursor: pointer;
  font-weight: bold;
  color: #4c4c4c;
  margin-right: 2px;
  background-color: #f1f1f1;
}

.ritem:hover {
  background-color: #e0e1e2;
}

.ritem.checked {
  background-color: var(--un-primary-back-color);
  color: white;
}

</style>