<template>

  <div class="param">

    <div :class="'setup_ico ' + ico"></div>

    <div class="bx">
      <div class="vl">
        <input type="date" @keyup="change" @change="change" v-model="edited_num" class="inp_fm">        
      </div>
      <div class="units">
        <div  
          v-for="(opt,opt_key) in units"
          :key="opt_key"
          :class="'unit select unit_' + units.length">
          {{ opt.name }}
        </div>
      </div>
    </div>

    <div class="hnt">
      {{ param }}
    </div>
     
  </div>
  
</template>


<script setup>

const emits = defineEmits(['update:num']);

const props = defineProps({
  num: {      
    required: true,
  },
  ico: {
    type: String,
    required: true,
  }, 
  unit: {
    type: String, 
  },
  units: {
    type: Array, 
  },
  param: {
    type: String, 
  },
});

const edited_num = ref(props.num);

const change = () => {
  emits('update:num', edited_num);
};


watch(() => props.num, (val) => {
  edited_num.value = val
});




// export default {
//   props: {
//     num: {
//       type: String,
//       default: '',
//       required: true,
//     },
//     ico: {
//       type: String,
//       required: true,
//     },
//     units: {
//       type: Array,
//       required: true,
//     },
//     param: {
//       type: String, 
//     }, 
//   },
//   data() {
//     return { 
//       edited_num: this.num
//     };
//   }, 

//   mounted () {
   
//   },
//   methods: {
//     change(){            
//       this.$emit('update:num', this.edited_num);
//     }
//   }
// }
</script>
 


<style scoped>
  @import '@/assets/css/param.css';
</style>

<style scoped>
   
 



</style>