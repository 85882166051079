<template>
  <div>    
 

    <div class="divider">{{ $t('create_week_view_navigation_title') }}</div>
    
    <DiaryEditProgress
      :steps="progress_steps"
      :states="progress_states"
      />
 
 
    <div class="divider" ref="elWeekPhotos">{{ $t('photo_video_manager_external_title') }}</div>        

    <WeekEditWarningPhotos/>

    <div class="udr_bx">
      <UiUploader
        :enable-drag-drop="!edited_week.items_photo?.length"
        @changed="changePhotos" 
        @uploaded="uploadedPhotos" 
        @progress="uploadingPhotos" 
        @errored="erroredPhotos"
      />
    </div>
    


 
    <draggable 
      v-model="edited_week.items_photo" 
      item-key="id" 
      v-if="edited_week.items_photo" 
      @start="drag=true"  
      @end="drag=false" 
      class="photos"
      delay-on-touch-only="250"
      delay="250"
      animation="200"
        ghost-class="photos_ghost"
      >

        <template #item="{element}">
          <WeekEditPhoto
            :item="element"
            v-show="element?.is_remove != 1"
            @remove="removePhoto(element.id)"
            @update:item="element = $event"
            @rotate="saveRotate"
            />
        </template>


    </draggable>
     

    <!-- <VueDraggable 
        v-if="edited_week.items_photo" 
        @start="drag=true"  
        @end="drag=false" 
        delay-on-touch-only="250"
        delay="250"
        animation="200"
        class="photos"
        ghost-class="photos_ghost"
        v-model="edited_week.items_photo" 
        item-key="id" 
        > 


          <WeekEditPhoto
            v-for="(element,element_key) in edited_week.items_photo"
            :item="element"
            v-show="element?.is_remove != 1"
            @remove="removePhoto(element.id)"
            @update:item="element = $event"
            @rotate="saveRotate"
            />


      </VueDraggable> -->


    <div class="divider" ref="elWeekCondition">{{ $t('create_week_view_conditions_title') }}</div>    
 
    <div class="parameters">

      <WeekEditParamDate       
        :num="edited_week.props.germ_date"
        :ico="'setup_ico_ph'"
        :param="'Germination Date'"
        :units="[{id: 'date', name: $t('create_week_view_conditions_date_label')}]"
        @update:num="edited_week.props.germ_date = $event"
        />

    </div>

    <div class="divider">{{ $t('create_week_view_germ_seeds_title') }}</div>    
    
    <div class="seeds_germ">
      <!-- {{ props.diary.items_seed }} -->
      <!-- {{ edited_week.item_germination.items_seed }} -->
      <WeekEditSeedGerm
        v-for="(opt,opt_key) in edited_week.item_germination.items_seed"
        :key="opt_key"
        :item="opt"
        @update:item="opt = $event"
        />

    </div>
 

    <div class="divider">{{ $t('create_week_view_nutrients_title') }}</div>    
    

    <div class="nutrients">

      <WeekEditNutrient
        v-if="edited_week.item_germination.items_nutrient"
        v-for="(opt,opt_key) in edited_week.item_germination.items_nutrient"
        v-show="opt?.is_remove != 1"
        :key="opt_key"
        :nutrient="opt"
        :val="opt.val"
        :default_measure="useAuth().getNutrient()"        
        :measure="opt.unit"        
        :is-presaved="edited_week.is_presaved"
        @remove="removeNutrient(opt_key)"        
        @choose="changeUniversal(opt, $event)"
        @update:val="opt.val = $event" 
        @update:measure="opt.unit = $event" 
        />

      <WeekEditNutrientSelectBox    
        @choose="chooseNutrient"
        />

    </div>

    <div class="divider">{{ $t('universal_grow_germ_method_title') }}</div>    
     


    <WeekEditSelectGermMethodRadioBox
      :list="$constants.typeGermMethodShort"
      :val="edited_week.item_germination.germ_method"      
      @update:val="edited_week.item_germination.germ_method = $event"
      /> 
     
    <div class="divider" ref="elWeekComment">{{ $t('create_week_view_owner_comment_title') }}</div>    

    <UiTextArea 
      :text="edited_week.text"
      @change:text="edited_week.text = $event"
      />


    <UiButtons>
      <UiButton type="primary" :is-loading="props.isLoadingSave" :name="$t('universal_button_save')" @click="save"/>      
      <UiButton :name="$t('universal_button_cancel')" @click="cancel"/>              
    </UiButtons>


  </div>
</template>



<script setup>
import draggable from 'vuedraggable'

const route = useRoute()
const router = useRouter()
const { $progressBarContent, $api, $popup } = useNuxtApp()
const { t } = useI18n()
const emits = defineEmits(['update:faza', 'canceleditweek', 'saveweek'])
const elWeekCondition = ref(null)
const elWeekPhotos = ref(null)
const elWeekComment = ref(null)

const props = defineProps({
  week: {
    type: Object,
    required: true,
  },
  diary: {
    type: Object,
    required: true,
  },
  isLoadingSave: {
    type: Boolean,
    required: false,
    default: false,
  },
})

var link = ref(route.params['week'])
var edited_week = ref(props.week)
var progress_steps = ref([
  {
    id: 'photos',
    name: t('create_week_view_navigation_photos_videos'),
    hintGood: t('create_week_view_navigation_now_talking'),
    hintNormal: t('create_week_view_navigation_more_photos_videos'),
    hintBad: t('create_week_view_navigation_some_photos_videos'),
    el: elWeekPhotos,
  }, 
  {
    id: 'conditions',
    name: t('create_week_view_navigation_conditions'),
    hintGood: t('create_week_view_navigation_good_to_grow'),
    hintNormal: t('create_week_view_navigation_add_some_more'),
    hintBad: t('create_week_view_navigation_whats_conditions'),
    el: elWeekCondition,
  },
  {
    id: 'comment',
    name: t('create_week_view_navigation_comment'),
    hintGood: t('create_week_view_navigation_cool_info'),
    hintNormal: t('create_week_view_navigation_wanna_tell'),
    hintBad: t('create_week_view_navigation_share_ideas'),
    el: elWeekComment,
  },
])

const progress_states = computed(() => {
  return $progressBarContent.calcWeekGer(edited_week.value);
})
 

const changePhotos = function(uploading_id, thumbnail){
  edited_week.value.items_photo = edited_week.value.items_photo || [];
  edited_week.value.items_photo.push({is_loading: true, uploading_id: uploading_id, thumbnail: thumbnail});
}

const findReplaceUploadingPhoto = function(uploading_id, item){
  edited_week.value.items_photo.forEach((opt, opt_key) => {
    if(opt.uploading_id == uploading_id){
      edited_week.value.items_photo[opt_key] = item; 
    }
  });
}

const findReplaceUploadingPhotoProgress = function(uploading_id, progress){
  edited_week.value.items_photo.forEach((opt, opt_key) => {
    if(opt.uploading_id == uploading_id){
      edited_week.value.items_photo[opt_key]['progress'] = progress; 
    }
  });
}

const uploadedPhotos = function(item, uploading_id){      
  edited_week.value.items_photo = edited_week.value.items_photo || [];
  // edited_week.value.items_photo.push(item);   
  findReplaceUploadingPhoto(uploading_id, item);
}

const uploadingPhotos = function(progress, uploading_id){      
  edited_week.value.items_photo = edited_week.value.items_photo || [];
  findReplaceUploadingPhotoProgress(uploading_id, progress);
}

const erroredPhotos = function(uploading_id){      
  $popup.error('Error uploading file');
  removePhoto(null, uploading_id);
}

const removePhoto = function(id, uploading_id){            
  edited_week.value.items_photo.forEach((opt, opt_key) => {
    if(opt.id && opt.id == id){
      edited_week.value.items_photo[opt_key].is_remove = 1; 
    }else if(opt.uploading_id && opt.uploading_id == uploading_id) {
      edited_week.value.items_photo[opt_key].is_remove = 1; 
    }
  });
}

const chooseNutrient = function(item){      
  edited_week.value.item_germination.items_nutrient = edited_week.value.item_germination.items_nutrient || [];
  edited_week.value.item_germination.items_nutrient.push(item);      
}

const changeFaza = function(vl){     
  emits('update:faza', edited_week.value);
}

const removeNutrient = function(key){            
  // edited_week.value.item_germination.items_nutrient.splice(key,1);      
  edited_week.value.item_germination.items_nutrient[key].is_remove = 1;    
}

const changeUniversal = (old_item, item, category) => {
  if (old_item) {
    edited_week.value.item_germination.items_nutrient = edited_week.value.item_germination.items_nutrient.map((el) => {
      if (el === old_item) {
        return item
      }
      return el
    })
  } else {
    diaryData.value.items_equip.push(item)
  }
}


const changeHeight = function(vl){
  // console.log('change num: ' + vl);
  // edited_week.value.item_germination.height = vl;
}

const cancel = function(){  
  emits('canceleditweek', edited_week.value.id)
}

const save = function(){            
  emits('saveweek', edited_week.value)
}

const setPhotoLoading = function(id, state){
  edited_week.value.items_photo.forEach((opt, opt_key) => {
    if(opt.id == id){
      console.log('is_loading photo' + id + ' state: ' + state);
      edited_week.value.items_photo[opt_key].is_loading = state;
    }
  });
}

const setPhoto = function(id, item){
  console.log('setPhoto photo' + id + ' item: ' + item.size_m);
  edited_week.value.items_photo.forEach((opt, opt_key) => {
    if(opt.id == id){
      edited_week.value.items_photo[opt_key].size_xl = item.size_xl;
      edited_week.value.items_photo[opt_key].size_l = item.size_l;
      edited_week.value.items_photo[opt_key].size_m = item.size_m;
      edited_week.value.items_photo[opt_key].size_s = item.size_s;
      edited_week.value.items_photo[opt_key].size_xs = item.size_xs;
    }
  });
}

const saveRotate = function(item, rotate_value){

  setPhotoLoading(item.id, true);

  $api.postRotatePhoto(item.id, rotate_value)
  .then((res) => {
    setPhotoLoading(item.id, false);
    setPhoto(item.id, res.data.value.data);
  })
  .catch((err) => {
    setPhotoLoading(item.id, false);
    $popup.error('Error rotate photo');
  })
}


watch(() => props.week, (new_props_week, old_props_week) => {
  edited_week.value = new_props_week;
}, {deep: true});


</script>

<style scoped>

  .parameters{
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
 

  .photos{
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap:15px;
    -webkit-user-select: none; 
    -ms-user-select: none; 
    user-select: none;
  }
  .photos_ghost{
  opacity: 0.5;
  background: #bee85d;
  }
  .udr_bx{
    text-align: center;
    margin: 1rem;
  }

</style>
