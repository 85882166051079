<template>

  <div :class="'sl' + (showed ? ' showed ' : '')" v-click-outside="hide">
 

    <div class="hnt">
      {{  }}
    </div>

    <div class="ched" @click="toggle">
      {{ selectedOption.name }}
      <i class="icon-caret-down"></i>
    </div>
    
    <div class="opts">
      <template
        v-for="option in options"
        >
        <div 
          @click="clickOption(option)"
          :class="'opt' + (option.id == edited_val ? ' active ' : '')"
          >
          {{ option.name }}
        </div>        
      </template>
    </div>
 

  </div>
  
</template>

<script setup>

const emits = defineEmits(['choose:val'])

const props = defineProps({
  val: {
    type: String
  },
  def: {
    type: String
  },
  options: {
    type: Array
  },

})

const edited_val = ref(props.val ? props.val : props.def);
const showed = ref(false);

const selectedOption = computed(() => {
  var sel = {id: '', name: ''};
  for(var option of props.options){
    if(option.id == edited_val.value) sel = option;
  }
  return sel;
})

const hide = () => {
  if(showed.value) 
    toggle();
}

const toggle = () => {
  showed.value = !showed.value;
}

const clickOption = (option) => {
  choose(option.id);
  toggle();
}

const choose = (id) => {
  edited_val.value = id;
  emits('choose:val', edited_val.value);
}

 
</script>

<style scoped lang="scss">


.sl{
  position: relative;
  display: flex;
  align-content: center;

    width: 100%;
    justify-content: center;
}
.sl.right{
  justify-content: flex-end;
}
.sl .hnt{

}
.sl .ched{  
  font-weight: bold;
  margin-left: 5px;
  position: relative;
  display: flex;
  align-content: center;
  cursor: pointer;
}
.sl .opts{
  display: none;
  position: absolute;
  right: 0;
  top: 20px;
  background-color: white;
  z-index: 10;
  padding: 0;
  border-radius: 3px;
  box-shadow: 0px 2px 3px 0px rgb(34 36 38 / 15%);
  border: 1px solid rgba(34, 36, 38, 0.15);
  min-width: 140px;
    font-size: inherit;
    width: 100%;

}
.sl.showed .opts{
  display: initial;
}

.sl .opts .opt{
  padding: 5px 15px;
  cursor: pointer;
}
.sl .opts .opt:hover{
  background-color: rgb(236, 236, 236);
}
.sl .opts .opt.active{
  opacity: 0.5;
  pointer-events: none;
}
</style>
